<template>
  <div>
    <van-action-sheet v-model="show">
      <img class="skuClose" @click="show = false" src="@/assets/images/goods/goodsDetail/skuClose.png" alt="">
      <div class="content">
        <div class="goodsInfo">
          <img :src="product.image" alt="">
          <div class="info">
            <div class="price">
              <span>¥</span>
              <span>{{ product.price }}</span>
            </div>
            <div class="name">
              {{ product.name }}
            </div>
          </div>
        </div>

        <!-- 选择规格方式 -->
        <div class="skuBody">

<!--          <div class="start">-->
<!--            开始智能选型-->
<!--          </div>-->

          <img :src="product.cmimage" class="rule" alt="">

          <cp-new-sku-custom ref="cpNewSkuCustom"
                             :product.sync="product"
                             :after-sales-order-id.sync="afterSalesOrderId"
                              :prop-data.sync ="propData"
                              :basket-count.sync="basketCount"
                             :key="timer"
                             @onSubmit="onSubmit"/>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>

import {mapActions} from "vuex";
import Vue from 'vue'
import CpNewSkuCustom from "./cpNewSkuCustom.vue";
//skuIndex,
export default {
  // components: {newSkuCustom},
  name: "newSku",
  components: {CpNewSkuCustom},
  props: {
    afterSalesOrderId:{
      type: String,
      default: "",
    }
  },
  watch: {
    id: {
      handler(val) {
        if (val) {
          this.id = val
          this.queryProduct(val);
        }
      },
      immediate: true,
    },
    cartId:{
      handler(val) {
        if (val) {
          this.$refs.cpNewSkuCustom.cartId = val
        }
      },
    },
    afterSalesOrderId:{
      handler(val) {
        if (val) {
          this.afterSalesOrderId = val
        }
      },
      immediate: true,
    }
  },
  data() {
    return {
      show: false,
      callback: null,
      product: {},
      id:"",
      cartId:"",
      timer:'',
      //购物车数据
      propData:[],
      //购物车总shul
      basketCount:1,
    }
  },
  activated() {
    this.timer = new Date().getTime();
  },
  methods: {
    ...mapActions(['queryShoppingCartNum']),
    queryProduct(id) {
      this.$http.get(`/product/h5_product/queryProductInfo/${id}`).then(res => {
        let product = res.data.product;
        product.imgs = product.image.split(',');
        this.product = product;
      });
    },
    showPop(callback = null,propData=[],basketCount=1) {
      this.timer = new Date().getTime();
      this.propData = propData
      this.basketCount = basketCount;
      if (callback) {
        this.callback = callback
      } else {
        this.callback = null
      }
      this.show = true
    },

    onTypeSelect(type) {
      this.type = type
    },
    onSubmit(data) {
      if (this.callback) {
        this.callback(data)
        this.show = false
        return;
      }

      if (data.type === 'BUY') {
        this.$router.push({path:'/order/submit',query:data})
      } else if (data.type === 'JOINCART') {
        // TODO
        this.queryShoppingCartNum({userId:Vue.prototype.userId});
      } else if (data.type === 'CONFIRM') {
        // TODO
        this.$router.push({path:'/order/submit',query:data})
      }

      this.show = false
      // TODO SERVER
      // 否则就发起请求
    },
  }
}
</script>
<style lang="less" scoped>
.skuClose {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 26px;
  right: 19px;
  z-index: 11;
}

.content {
  padding: 0 18px;
  transition: all .2s;
  position: relative;

  .goodsInfo {

    display: flex;
    align-items: flex-end;
    position: sticky;
    top: 0;
    background-color: #fff;
    padding: 24px 0 10px 0;
    z-index: 10;

    img {
      width: 100px;
      height: 84px;
      border-radius: 5px;
      margin-right: 15px;
    }

    .price {
      height: 15px;

      span {
        font-weight: bold;
        color: #D53A26;

        &:first-child {
          font-size: 12px;
          margin-right: 3px;
        }

        &:last-child {
          font-size: 18px;
        }
      }
    }

    .name {
      margin-top: 12px;
      font-size: 10px;
      font-weight: 400;
      color: #333333;
      line-height: 15px;
    }
  }


}

.start {
  height: 25px;
  background: #F3E9FE;
  border-radius: 4px;
  line-height: 25px;
  padding: 0 10px;

  font-size: 10px;
  font-weight: 400;
  color: #C226D5;
  line-height: 25px;

}


.rule {
  width: 100%;
  margin-bottom: 10px;
}
</style>
