<template>
  <div class="skuBody">
    <div class="subTitle">
      {{ currentSku.skuName }}（<span>{{ currentSku.need }}</span>)件
    </div>

    <div class="selectSku" v-for="(select,temp) in currentSku.selectSkuList" :key="temp" @click="unSelect(temp)">
      {{ selectedProduct(temp) }}
      <div :style="currentSku.thisIndex === temp?'border-bottom: 1px solid #e78f8f;':''">
        <span>已选:</span>
        <span  v-for="(unSepc,unTemp) in select.prop">
          <span class="item" v-if=" !unSepc.specValId">{{ unSepc.specValName }}</span>
          <van-badge v-else :content="unSepc.num" :id="'selectSku'+temp+'_'+ unSepc.specValId + '_'+unSepc.random">
              <div class="item active">
                {{ unSepc.specValName }}
              </div>
          </van-badge>
        </span>
      </div>
    </div>

    <div class="main-content">
      <div v-for="(sku,temp) in currentSku.specificationVos" :key="sku.id">
        <div class="title" v-if="temp === 0">
          <div class="text">
            规格<span>（必选）</span>
          </div>
        </div>
        <div class="title" v-else>
          <div class="text">
            {{ sku.name }}<span>（必选）</span>
          </div>
        </div>

        <div class="skuList">
          <div v-for="(skuVal,tempVal) in sku.specificationValues"
               :key="tempVal"
               :id="'sku' + skuVal.id"
               :class="{skuItem: true,active: isActive(temp, skuVal.id), disabed: false }"
               @click="selectSku(skuVal,temp)"
          >
            <div class="booking tag" v-if="skuVal.isBooking">预售</div>
            <div class="outofstock tag" v-if="!skuVal.able">缺货</div>
            {{ skuVal.name }}
          </div>
        </div>

        <div class="special" v-if="showSpecial">
          <van-row :gutter="14">
            <van-col :span="12">
              <div class="inputItem long">
                <span class="name">身高</span>
                <input type="number" @blur="specialForm.height > 250 ? specialForm.height = 250 : void 0" v-model="specialForm.height" @input="setspecia">
                <span class="unit">厘米</span>
              </div>
            </van-col>
            <van-col :span="12">
              <div class="inputItem long">
                <span class="name">体重</span>
                <input type="number" @blur="specialForm.weight > 150 ? specialForm.weight = 150 : void 0" v-model="specialForm.weight"  @input="setspecia">
                <span class="unit">公斤</span>
              </div>
            </van-col>
            <van-col :span="8">
              <div class="inputItem">
                <span class="name">胸围</span>
                <input type="number" @blur="specialForm.chest > 200 ? specialForm.chest = 200 : void 0" v-model="specialForm.chest"  @input="setspecia">
                <span class="unit">厘米</span>
              </div>
            </van-col>
            <van-col :span="8">
              <div class="inputItem">
                <span class="name">腰围</span>
                <input type="number" @blur="specialForm.waist > 200 ? specialForm.waist = 200 : void 0" v-model="specialForm.waist"  @input="setspecia">
                <span class="unit">厘米</span>
              </div>
            </van-col>
            <van-col :span="8">
              <div class="inputItem">
                <span class="name">臀围</span>
                <input type="number" @blur="specialForm.hip > 200 ? specialForm.hip = 200 : void 0" v-model="specialForm.hip"  @input="setspecia">
                <span class="unit">厘米</span>
              </div>
            </van-col>
          </van-row>

        </div>
      </div>
    </div>

    <van-row class="buttonGroup" :gutter="14">
      <van-col :span="24">
        <van-button block type="primary" size="small" @click="onConfirm" style="border-radius: 88px;">
          确定
        </van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import {Decimal} from "decimal.js";
import {gsap} from "gsap";

export default {
  components: {  },
  name: "newSkuUniForm",
  props: {
    specData: Object,
    skuIndex: Number
  },
  data() {
    return {
      index: 0,
      number: 1,
      skuId: null, //当前选中的SKUID
      showSpecial: false, //是否展示特码输入框
      currentSku: {},
    }
  },
  watch: {
    specData: {
      immediate: true,
      handler() {
        if (this.specData && Object.values(this.specData).length > 0) {
          this.currentSku = this.specData
          this.currentSku.specificationVos.forEach((spec, temp) => {
            spec.specificationValues.forEach(specVal => {

              specVal.able = this.isAble(this.currentSku, temp, specVal)
            })
          })
          // this.skuId = this.currentSku.sku[this.index].skuId //当前选中的SKUID
          // this.showSpecial = false //是否展示特码输入框
          // if (this.currentSku.sku[this.index].specialForm) {
          //   this.showSpecial = true
          //   this.specialForm = this.currentSku.sku[this.index].specialForm
          // }
        }
      }
    }
  },

  computed: {
    total() {
      return this.number * 55;
    }
  },

  methods: {
    unSelect(temp) {
      this.currentSku.thisIndex = temp;
      this.$forceUpdate();
    },
    isActive(temp, id) {
      let sku = this.currentSku;
      return sku.selectSkuList[sku.thisIndex].prop[temp].specValId === id;
    },
    selectSku(item, tempVal) {
      if (!item.able) {
        // 如果是缺货 不可选
        return;
      }

      //获取当前选择的索引
      let thisIndex = this.currentSku.thisIndex;

      let selectSku = this.currentSku.selectSkuList[thisIndex];

      //筛选取消选择的商品数量
      this.currentSku.listMxSubProduct.forEach(item => {
        /**
         *  selectSku.id =  ''或者 商品id(已选择商品)
         *  1.第一次选择为空 不会清除商品选择数量
         *  2.第二次选择已有商品id 找到对应的明细商品清除选择数量
         */
        if(selectSku.id == item.productSpecItem.mxId){
          item.selectedNum -= selectSku.num;
        }
      })

      /**
       * index 当前选择哪个包商品
       * thisIndex 当前选择包中第几个上哦
       * tempVal 当前选择商品中第几个规格
       */
      let d = this.currentSku.selectSkuList[thisIndex].prop[tempVal];
      //第一次选择和选择其他sku
      if (!d.specValId || d.specValId !== item.id) {
        d.random = Math.floor(Math.random() * 1e3)
        d.specValId = item.id;
        d.specValSku = item.sku;
        d.specValName = item.name;
        this.currentSku.selectSkuList[thisIndex].prop[tempVal] = d
      } else {
        //取消选择sku
        d.specValId = null;
        d.specValSku = '';
        d.specValName = (tempVal > 0 ? this.currentSku.specificationVos[tempVal].name : "尺码");
        this.currentSku.selectSkuList[thisIndex].prop[tempVal] = d

        //取消选择
        this.currentSku.selectSkuList[thisIndex].id = ""
        this.currentSku.selectSkuList[thisIndex].name = ""
        this.currentSku.selectSkuList[thisIndex].price = 0
        this.currentSku.selectSkuList[thisIndex].totalPrice = 0
      }

      this.currentSku.specificationVos.forEach((spec, temp) => {
        spec.specificationValues.forEach(specVal => {
          specVal.able = this.isAble(this.currentSku, temp, specVal)
        })
      })

      //判断库存是不是大于订单的数量
      let strSpecSku = "";
      selectSku.prop.forEach((map) => {
        strSpecSku += map.specValSku + "_";
      })
      strSpecSku = strSpecSku.slice(0, -1);
      let thisItem = null;
      let number = this.number;
      let can = this.currentSku.listMxSubProduct.every(item => {
        let res = true;
        //属性和规格全部选完后才能组成商品 来判断库存
        if (item.prop.indexOf(strSpecSku) !== -1 && item.prop.length === strSpecSku.length) {
          //1.先选上该商品数量
          item.selectedNum += selectSku.num;
          //2.判断库存 决定是否能选择
          //当前库存-选择的库存 >= 需要选择的数量  true-> 就可以选择  false ->不可以选择
          res = item.marketable >= new Decimal(item.selectedNum).mul(new Decimal(number)).toNumber();
          //3.true-> 可以选择  false-> 不能选择 数量需要退回
          item.selectedNum = res ? item.selectedNum : item.selectedNum-1;

          thisItem = item;
        }
        return res;
      })

      if (!can) {
        this.$dialog.alert({
          message: thisItem.productSpecItem.name + '商品库存小于' + thisItem.marketable,
        }).then(() => {
          // on close
        });
        //选择当前sku
        d.specValId = null;
        d.specValSku = '';
        d.specValName = (tempVal > 0 ? this.currentSku.specificationVos[tempVal].name : "尺码");
        this.currentSku.selectSkuList[thisIndex].prop[tempVal] = d
        this.currentSku.selectSkuList[thisIndex].id = ""
        this.currentSku.selectSkuList[thisIndex].name = ""
        this.currentSku.selectSkuList[thisIndex].price = 0
        this.currentSku.selectSkuList[thisIndex].totalPrice = 0
        return false;
      }



      //是否选择完所有sku
      can = selectSku.prop.every(select => {
        return null === select.specValId
      });

      //选完所有sku，当前索引是不是最大的索引 是就不增加 如果不是就+1在去选择尺码
      // if(this.currentSku.thisIndex<this.currentSku.selectSkuList.length-1){
      //   this.currentSku.thisIndex++;
      // }
      this.$forceUpdate();
    },

    isAble(sku, temp, specVal) {
      // 深拷贝 避免被影响
      let copySelectSku = JSON.parse(JSON.stringify(sku.selectSkuList[sku.thisIndex]));
      copySelectSku.prop[temp].specValSku = specVal.sku;
      let flag = sku.listMxSubProduct.some(item => {
        // eslint-disable-next-line no-unused-vars
        let i = 0;
        copySelectSku.prop.forEach(select => {
          if (select.specValSku !== '' && item.prop.indexOf(select.specValSku) !== -1 && !item.isOutOfStock) {
            i++;
          } else if (select.specValSku === '') {
            i++;
          }
        })
        return i === copySelectSku.prop.length
      })
      return flag
    },

    doAni(item, thisIndex) {

      this.$nextTick(() => {

        let formSkuItem = document.querySelector('#sku' + item.specValId);
        if (null == formSkuItem) {
          return;
        }
        let targetSkuItem = document.querySelector('#selectSku' + thisIndex + "_" + item.specValId + '_' + item.random);
        let tween = gsap.timeline({delay: 0});
        tween.from('#selectSku' + thisIndex + "_" + item.specValId + '_' + item.random, {
          duration: 1,
          ease: 'power2',
          opacity: 0,
          y: formSkuItem.offsetTop - targetSkuItem.offsetTop,
          x: formSkuItem.offsetLeft - targetSkuItem.offsetLeft,
          padding: [10, 13]
          // height: formSkuItem.offsetHeight,
          // width: formSkuItem.offsetWidth
        }, '>')
      })
    },
    selectedProduct( temp) {
      let skuName = '';
      let sku = this.currentSku;
      let listMxSubProduct = sku.listMxSubProduct;
      let selectSku = sku.selectSkuList[temp];
      let propSkuArr = [];

      let can = selectSku.prop.every(select => {
        propSkuArr.push(select.specValSku)
        return select.specValId
      });

      if (can) {
        listMxSubProduct.forEach(mxSubProduct => {
          if (mxSubProduct.prop === propSkuArr.join("_")) {
            skuName = mxSubProduct.productSpecItem.name;
            selectSku.price = mxSubProduct.productSpecItem.price;

            // 乘法
            selectSku.totalPrice = new Decimal(selectSku.price).mul(new Decimal(selectSku.num)).toNumber();
            selectSku.name = skuName;
            selectSku.id = mxSubProduct.productSpecItem.mxId;
            return true;
          }
        })
      }
      this.currentSku.selectSkuList[temp] = selectSku;
      //计算最大可选数量
      this.calculateMaximumNumberOfOptions();
    },
    calculateMaximumNumberOfOptions(){
      let maxStock = 999;
      this.currentSku.listMxSubProduct.forEach(mx => {
        this.currentSku.selectSkuList.forEach(select => {
            if (mx.productSpecItem.mxId === select.id) {
              //当前规格最大可选的数量
              let stock = new Decimal(mx.marketable).div(new Decimal(mx.selectedNum)).toFixed(0,Decimal.ROUND_DOWN);
              if(maxStock>stock){
                //最小数量对应的商品
                //找到所有选择的商品中最小选择 数量
                maxStock = stock
              }
            }
          })
        })

      if(999 == maxStock){
        this.maxNumber = 1;
      }else{
        this.maxNumber = maxStock;
      }
    },
    onConfirm() {
      this.$emit('onSubmit', this.skuIndex, this.currentSku); // 在sku 中发起请求， 这里只是对数据封装
    }
  }
}

</script>

<style lang="less" scoped>
.skuBody {
  padding-bottom: 70px ;
}

.subTitle {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  margin-top: 20px;
  span {
    color: #f00;
    font-weight: 500;
  }
}
.selectSku {
  padding: 5px 0 5px 0;
  border-bottom: 1px solid #F5F5F5;
  span {
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    line-height: 12px;
  }

  .item {
    margin-left: 8px;
    background: #F2F2F2;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    color: #999999;
    display: inline-block;
    padding: 3px 7px;
    transition: all .2s;
    &.active {
      height: 19px;
      background: #edebeb;
      border-radius: 3px;
      line-height: 19px;
      padding: 0 10px;
      font-size: 12px;
      font-weight: 400;
      color: #332d2d;
      margin-left: 15px;
      margin-bottom: 5px;
    }
    img {
      height: 9px;
      width: 9px;
      margin-left: 5px;
    }
  }
}
.title {
  margin: 12px 0 15px 0;
  display: flex;
  justify-content: space-between;
  .text {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
    line-height: 15px;
    span {
      color: #FF0000;
      font-weight: 500;
      font-size: 12px;
    }
    .item {
      height: 19px;
      background: #F9F9F9;
      border-radius: 3px;
      line-height: 19px;
      padding: 0 10px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      margin-left: 5px;
      margin-bottom: 5px;
      &.active {
        background-color: #F9EDEB;
        color: #333;

        img {
          height: 9px;
          width: 9px;
          margin-right: 5px;
        }
      }
    }
  }

}

.skuList {
  margin-right: -15px;
  .skuItem {
    display: inline-block;
    background: #F2F2F2;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #212121;
    padding: 10px 13px;
    margin-right: 13px;
    margin-bottom: 12px;

    position: relative;
    &.active {
      background: #F9EDEB;
      border: 1px solid #D6191C;
      border-radius: 4px;
      padding: 9px 12px;
      color: #D6191C;
    }
    &.specialItem {
      &::after {
        position: absolute;
        bottom: -18px;
        transform: translateX(-50%);
        left: 50%;
        content: '';
        border-bottom: 13px solid #F9EDEB;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
      }
    }
    .tag {
      height: 12px;
      width: 27px;
      border: 1px solid;
      border-radius: 2px;
      line-height: 12px;
      text-align: center;
      font-size: 10px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 10px;
      position: absolute;
      right: -7px;
      top: -6px;
      &.outofstock {
        border-color: #ccc;
        background: #CCCCCC;
      }

      &.booking {
        background: #D61619;
        border-color: #D61619;
      }
    }
  }
}


.special {
  padding: 5px 7px 5px 7px;
  margin-top: 4px;
  background: #F9EDEB;
  border-radius: 5px;
  margin-bottom: 19px;
  .inputItem {
    width: 100%;
    height: 28px;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 7px 0 7px 0;
    &.long {
      .name {
        padding-left: 9px;
      }
      .name::after {
        content: '*';
        color: #FF0000;
        font-size: 20px;
        font-style: normal;
        position: absolute;
        top: 3px;
        left: 0;
      }
    }
    .name {
      position: relative;
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      display: block;
      line-height: 12px;
      i {
        margin-right: 1px;

      }
    }
    input {
      border: none;
      font-size: 12px;
      font-weight: 400;
      color: #212121;
      text-align: center;
      max-width: 30%;
    }

    .unit {
      font-size: 10px;
      font-weight: 400;
      color: #999999;
    }
  }
}

.count {
  display: flex;
  justify-content: space-between;
  margin: 21px 0 0 0;
  .left {
    font-size: 10px;
    font-weight: 400;
    color: #333333;
    line-height: 10px;
    span {
      color: #D53A26;
    }
  }
  .price {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    line-height: 12px;
    span {
      font-weight: 500;
      color: #D53A26;

    }
  }
}
.buttonGroup { position: fixed; background-color: #fff; bottom: 0;left: 18px; right: 18px;  padding-top: 14px; padding-bottom: 14px;}
</style>
