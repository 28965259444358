<template>
  <div class="skuBody">
    <div class="main-content">
      <div class="package" v-for="(item,index) in skuList" :key="index">
        <div class="left">
          <div class="name">{{ item.skuName }}（<span>{{ item.need }}</span>)件</div>
          <!--          <div class="money">￥{{ item.price }}</div>-->
          <div class="sku" v-for="(select,temp) in item.selectSkuList" :key="temp">
            <span>已选:</span>
            <span v-for="(unSepc,unTemp) in select.prop">
              <span class="item" v-if=" !unSepc.specValId">{{ unSepc.specValName }}</span>
              <van-badge  v-else-if="unSepc.num>1" :content="unSepc.num" :id="'selectSku'+temp+'_'+ unSepc.specValId + '_'+unSepc.random">
                  <div class="item active">
                    {{ unSepc.specValName }}
                  </div>
              </van-badge>
              <div v-else class="item active">
                {{ unSepc.specValName }}
              </div>
            </span>
          </div>
        </div>
        <div class="btn default" @click="setSku(item, index)">选规格</div>
      </div>
    </div>


    <warm-prompt :reminder="product.tipmemo"/>
    <div style="height: 20px"></div>
    <div style="width: 100%; background-color: #f5f5f5;" v-if="product.bzflag">
      <van-field
          style="background-color: #f0f0f0;border-radius: 5%;"
          v-model="product.message"
          rows="2"
          autosize
          type="textarea"
          maxlength="50"
          placeholder="请输入备注"
          show-word-limit
      />
    </div>

    <van-row
        class="buttonGroup"
        v-if="$route.path.indexOf( '/goodsDetail') !== -1
        || $route.path.indexOf( '/store/index') !== -1 ||
        $route.path.indexOf( '/afterSales/detail') !== -1"
        :gutter="14">
      <div class="title">
        <div class="text">
          数量
        </div>
        <van-stepper button-size="20" v-model="number" min="1" :max="maxNumber"/>
      </div>

      <div class="count">
        <div class="left">
          共计包数量：<span>{{ number }}</span>
        </div>

        <div class="price">
          共计商品金额：<span>¥&nbsp;{{ total | money }}</span>
        </div>
      </div>

      <div v-if="'' !== afterSalesOrderId">
        <van-col :span="24">
          <van-button color="#D53A26" block type="primary" round size="small" @click="onBuy">
            立即购买
          </van-button>
        </van-col>
      </div>
      <div v-else-if="null === product.storeId">
        <van-col :span="12">
          <van-button color="#FF553F" block type="primary" round size="small" @click="onJoinCart">
            加入购物车
          </van-button>
        </van-col>
        <van-col :span="12">
          <van-button color="#D53A26" block type="primary" round size="small" @click="onBuy">
            立即购买
          </van-button>
        </van-col>
      </div>

      <div v-else>
        <van-col :span="24">
          <van-button color="#FF553F" block type="primary" round size="small" @click="onJoinCart">
            加入购物车
          </van-button>
        </van-col>
      </div>
    </van-row>

    <van-row class="buttonGroup" v-else :gutter="14">
      <div class="title">
        <div class="text">
          数量
        </div>
        <van-stepper button-size="20" v-model="number" min="1" :max="maxNumber" />
      </div>

      <div class="count">
        <div class="left">
          共计包数量：<span>{{ number }}</span>
        </div>

        <div class="price">
          共计商品金额：<span>¥&nbsp;{{ total | money }}</span>
        </div>
      </div>
      <van-col :span="24">
        <van-button block type="primary" size="small" @click="onConfirm">
          确定
        </van-button>
      </van-col>
    </van-row>

    <van-action-sheet v-model="specShow" style="background-color: #fbfbfb;">
      <img class="skuClose" @click="closeSpec" src="@/assets/images/goods/goodsDetail/skuClose.png" alt="">
      <div class="content">
        <new-sku-uniform
            :specData="selectedSpec"
            :skuIndex="index"
            @onSubmit="onSpecConfirm"
        />
      </div>
    </van-action-sheet>

  </div>
</template>


<script>

import {mapState} from "vuex";
import {Decimal} from "decimal.js";
import Vue from "vue";
import warmPrompt from "../warmPrompt.vue";
import newSkuUniform from './newSkuUniform.vue'
import {validatenull} from '../../../../util/util'

export default {
  name: "cpNewSkuCustom",
  components: {warmPrompt,newSkuUniform},
  data() {
    return {
      number: 1,
      skuList: [],
      productPack: [],//商品包
      selectedSpec:{},
      index: null,
      maxNumber:1,
      specShow:false,
      load: false,//加载
      //总金额
      total:0,
    }
  },
  props: {
    product: {
      type: Object,
      "default":()=> {return {}},
    },
    propData: {
      type: Array,
      "default":()=> {return []}
    },
    cartId: {
      type: String,
      "default": "",
    },
    afterSalesOrderId:{
      type: String,
      "default": "",
    },
    basketCount:{
      type: Number,
      "default": 0,
    }
  },
  watch: {
    product: {
      handler(val) {
        if (val.id) {
          this.product = val;
          this.querySku();
        }
      },
      immediate: true,
    },
    cartId: {
      handler(val) {
        if (val) {
          this.cartId = val
        }
      },
      immediate: true,
    },
    number:{
      handler(){
        this.calcInventoryIsSufficient();
        this.totalUpdate();
      },
      immediate: true,
    }
  },
  computed: {
    ...mapState(['deptSet']),
    userId() {
      return Vue.prototype.userId;
    }
  },
  methods:{
    //查询sku数据
    querySku() {
      this.$http.get(`/product/h5_product/queryReleasePackageCombinationSingleProduct/${this.product.id}/${Vue.prototype.deptId}/${this.product.libckflag}`).then(res => {
        this.initSkuData(res.data);
      })
    },
    initSkuData(data) {
      // 我们需要创建一个二维数组 来存取所选的skuId
      data.forEach(e => {
        //尺码属性选择
        let specArr = e.specificationVos.map((spec, index) => {
          //"未选"+
          let newSpec = Object.assign({}, spec);
          return {
            specValId: null,
            specValName: (index > 0 ? newSpec.name : "尺码"),
            specValSku: '',
            num: this.deptSet.pdspec === 0 ? e.need : 1
          };
        });
        //多层嵌套数组分配不同内存地址 JSON.parse(JSON.stringify(specArr))
        let selectSkuList = null;
        if (this.deptSet.pdspec === 0) {
          selectSkuList = Array.from({length: 1}, () => (JSON.parse(JSON.stringify({
            id: '',
            price: 0,
            num: e.need,
            totalPrice: 0,
            name: '',
            prop: specArr
          }))));
        } else {
          selectSkuList = Array.from({length: e.need}, () => (JSON.parse(JSON.stringify({
            id: '',
            price: 0,
            num: 1,
            totalPrice: 0,
            name: '',
            prop: specArr
          }))));
        }
        e.selectSkuList = selectSkuList;
        e.thisIndex = 0;//当前操作索引
        //这个刚开始检查库存更换到下一个组件中去做这个事情
        // e.specificationVos.forEach((spec, temp) => {
        //   spec.specificationValues.forEach(specVal => {
        //     specVal.able = this.isAble(e, temp, specVal)
        //   })
        // })
      })
      this.skuList = data
      this.$forceUpdate();
      this.shoppingCartItemReselection();
    },
    //购物车商品重选
    shoppingCartItemReselection(){
      if(!validatenull(this.propData)){
        let propData = this.propData;
        this.skuList.forEach(sku=>{
          sku.selectSkuList = [];
          for (let i = 0; i < sku.listMxSubProduct.length; i++) {
            let mx = sku.listMxSubProduct[i];
            for (let j = 0; j < propData.length; j++) {
              let prop = propData[j];
              if(prop.id == mx.productSpecItem.mxId){
                mx.selectedNum = mx.selectedNum+prop.num
                sku.selectSkuList.push(prop);
              }
            }
          }
        })
        this.totalUpdate();
        this.calculateMaximumNumberOfOptions();
        this.number = this.basketCount;
        this.$forceUpdate();
      }

    },
    onJoinCart() {
      if (!this.vaild()) {
        return;
      }
      if (!this.calcInventoryIsSufficient()) {
        return;
      }
      this.load = true;
      //1.先建立webscoket连接
      //2.再去请求
      //3.在提示
      let skuProductData = [];
      let mapProductData = {};
      this.skuList.forEach(sku => {
        sku.selectSkuList.forEach(select => {
          if (mapProductData[select.id]) {
            mapProductData[select.id].num = mapProductData[select.id].num + select.num;
          }else{
            mapProductData[select.id] = select;
          }
        })
      })
      for (let key in mapProductData) {
        skuProductData.push(mapProductData[key]);
      }

      let reserveProductData = [];
      let anyProductData = [];
      if (this.product.deliveryMode == 2) {
        reserveProductData.push({
          releaseProduct: this.product,
          skuProductData: skuProductData,
          cartId: this.cartId,
          totalNum: this.number,
          totalPrice: this.total,
        });
      } else if (this.product.deliveryMode == 1) {
        anyProductData.push({
          releaseProduct: this.product,
          skuProductData: skuProductData,
          cartId: this.cartId,
          totalNum: this.number,
          totalPrice: this.total,
        });
      }
      if (reserveProductData.length > 0) {
        this.$http.post(this.baseMallUrl + `/h5_mall/addShoppingCart/${Vue.prototype.userId}/${Vue.prototype.deptId}`, reserveProductData, {emulateJSON: true}).then(() => {
          this.$toast.loading({
            message: '加载中...',
            forbidClick: this.load,
          });
          this.querySku();
          this.$emit('onSubmit', {type: 'JOINCART', data: {}})// 在sku 中发起请求， 这里只是对数据封装
        })
      } else if (anyProductData.length > 0) {
        this.$http.post(this.baseMallUrl + `/h5_mall/addShoppingCart/${Vue.prototype.userId}/${Vue.prototype.deptId}`, anyProductData, {emulateJSON: true}).then(() => {
          this.$toast.loading({
            message: '加载中...',
            forbidClick: this.load,
          });
          this.querySku();
          this.$emit('onSubmit', {type: 'JOINCART', data: {}})// 在sku 中发起请求， 这里只是对数据封装
        })
      }
    },

    onBuy() {
      if (!this.vaild()) {
        return;
      }
      if (!this.calcInventoryIsSufficient()) {
        return;
      }
      let skuProductData = [];
      let mapProductData = {};
      this.skuList.forEach(sku => {
        sku.selectSkuList.forEach(select => {
          if (mapProductData[select.id]) {
            mapProductData[select.id].num = mapProductData[select.id].num + select.num;
          }else{
            mapProductData[select.id] = select;
          }
        })
      })

      for (let key in mapProductData) {
        skuProductData.push(mapProductData[key]);
      }

      let reserveProductData = [];
      let anyProductData = [];
      if (this.product.deliveryMode == 2) {//配送到校
        reserveProductData.push({
          releaseProduct: this.product,
          skuProductData: skuProductData,
          afterSalesOrderId: this.afterSalesOrderId,
          totalNum: this.number,
          totalPrice: this.total,
          price : new Decimal(this.total).div(new Decimal(this.number)).toNumber()
        });
      } else if (this.product.deliveryMode == 1) {//快递到家
        anyProductData.push({
          releaseProduct: this.product,
          skuProductData: skuProductData,
          afterSalesOrderId: this.afterSalesOrderId,
          totalNum: this.number,
          totalPrice: this.total,
          price : new Decimal(this.total).div(new Decimal(this.number)).toNumber()
        });
      }

      localStorage.setItem('reserveProductData', JSON.stringify(reserveProductData));
      localStorage.setItem('anyProductData', JSON.stringify(anyProductData));
      localStorage.setItem('afterSalesOrderId', this.afterSalesOrderId);
      localStorage.removeItem('store')
      this.$emit('onSubmit', {type: 'BUY', data: {}}); // 在sku 中发起请求， 这里只是对数据封装
    },
    //计算最大可选数量
    calculateMaximumNumberOfOptions(){
      let maxStock = 999;
      this.skuList.forEach(sku => {
        sku.listMxSubProduct.forEach(mx => {
          sku.selectSkuList.forEach(select => {
            if (mx.productSpecItem.mxId === select.id) {
              //当前规格最大可选的数量
              let stock = new Decimal(mx.marketable).div(new Decimal(mx.selectedNum)).toFixed(0,Decimal.ROUND_DOWN);
              stock = parseInt(stock)
              if(maxStock>stock){
                //最小数量对应的商品
                //找到所有选择的商品中最小选择 数量
                maxStock = stock
              }else if(stock>maxStock){
                maxStock = 998;
              }
            }
          })
        })
      })
      if(999 == maxStock){
        this.maxNumber = 1;
      }else{
        this.maxNumber = maxStock;
      }
    },
    //计算库存是否充足
    calcInventoryIsSufficient() {
      let number = this.number;
      let productName = "";
      let maxStock = 999;
      //是否有商品
      let isProduct=false;
      this.skuList.forEach(sku => {
        sku.listMxSubProduct.forEach(mx => {
          sku.selectSkuList.forEach(select => {
            if (mx.productSpecItem.mxId === select.id) {
              //当前规格最大可选的数量
              let stock = new Decimal(mx.marketable).div(new Decimal(mx.selectedNum)).toFixed(0,Decimal.ROUND_DOWN);
              stock = parseInt(stock);
              isProduct = true;
              if(maxStock>stock){
                //最小数量对应的商品
                productName = mx.productSpecItem.name;
                //找到所有选择的商品中最小选择 数量
                maxStock = stock
              }
            }
          })
        })
      })
      let can = true;
      //如果当前选择数量大于最小选择数量 需要提示库存不足
      if (number>maxStock) {
        can = false;
        //最大数量
        this.$toast({
          message:productName + '商品最多可购买' + maxStock,
          type:"fail",
          onClose:()=>{
            this.number = maxStock;
            this.maxNumber = maxStock;
          }
        });
      } else if(isProduct) {
        this.$toast('可以购买');
      }else{
        this.$toast('请先选择商品规格');
      }
      return can;
    },
    vaild() {
      /*for (let i = 0, len = this.selectSkuList.length; i < len; i++) {
        for (let n = 0, childLen = this.selectSkuList[i].length; n < childLen; n++) {
          let e = this.selectSkuList[i][n]
          if (!e || !e.skuId) {
            this.$toast('请选择完整规格');
            return false
          }
        }
      }*/
      let can = this.skuList.every(sku => {
        return sku.selectSkuList.every(select => {
          return select.prop.every(specVal => {
            return null != specVal.specValId;
          })
        })
      });


      if (!can) {
        this.$toast('请选择完整规格');
      }
      return can;
    },
    onConfirm() {
      if (!this.vaild()) {
        return;
      }
      if (!this.calcInventoryIsSufficient()) {
        return;
      }
      let skuProductData = [];

      this.skuList.forEach(sku => {
        sku.selectSkuList.forEach(select => {
          skuProductData.push(select)
        })
      })
      let reserveProductData = [];
      let anyProductData = [];
      let product = this.product;
      if (product.deliveryMode == '2') {
        reserveProductData.push({
          releaseProduct: this.product,
          skuProductData: skuProductData,
          cartId: this.cartId,
          totalNum: this.number,
          totalPrice: (this.total/this.number).toFixed(2),
        });
      } else if (product.deliveryMode == '1') {
        anyProductData.push({
          releaseProduct: this.product,
          skuProductData: skuProductData,
          cartId: this.cartId,
          totalNum: this.number,
          totalPrice: (this.total/this.number).toFixed(2),
        });
      }
      if(anyProductData.length>0){
        this.$emit('onSubmit', {type: 'CONFIRM', data: anyProductData}); // 在sku 中发起请求， 这里只是对数据封装
      }else if(reserveProductData.length>0){
        this.$emit('onSubmit', {type: 'CONFIRM', data: reserveProductData}); // 在sku 中发起请求， 这里只是对数据封装
      }
    },
    //一下是新增加的
    setSku(data, i) {
      this.index = i
      this.selectedSpec =  Object.assign({}, data);
      this.specShow = true
    },
    closeSpec() {
      this.selectedSpec = {}
      this.specShow = false
    },
    onSpecConfirm(index, data) {
      this.specShow = false
      this.skuList[index] = Object.assign({}, data);
      this.$forceUpdate();
      this.totalUpdate();
      this.calculateMaximumNumberOfOptions();
    },
    //总金额更新
    totalUpdate() {
      let totalPrice = 0;
      this.skuList.forEach(sku => {
        sku.selectSkuList.forEach(selectSku => {
          totalPrice = new Decimal(totalPrice).add(new Decimal(selectSku.totalPrice)).toNumber();
        })
      })

      this.total = new Decimal(this.number).mul(new Decimal(totalPrice)).toNumber();
    },
  }
}
</script>


<style lang="less" scoped>

.skuClose {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 26px;
  right: 19px;
  z-index: 12;
}
.skuBody {
  padding-bottom: 150px;
}
.main-content {
  background-color: #fff;
  padding: 5px 11px 0;
  border-radius: 5px;
  margin-bottom: 10px;
}
.content{
  padding: 0 0.48rem;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  position: relative;
}
.title {
  margin: 12px 0 15px 0;
  display: flex;
  justify-content: space-between;
  .text {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
    line-height: 15px;
    span {
      color: #FF0000;
      font-weight: 500;
      font-size: 12px;
    }
  }

}


.count {
  display: flex;
  justify-content: space-between;
  margin: 21px 0 15px 0;
  .left {
    font-size: 10px;
    font-weight: 400;
    color: #333333;
    line-height: 10px;
    span {
      color: #D53A26;
    }
  }
  .price {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    line-height: 12px;
    span {
      font-weight: 500;
      color: #D53A26;

    }
  }
}

.package {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid rgba(220, 220, 220, 1);
  padding-bottom: 15px;
  padding-top: 15px;
  &:last-child {
    border-bottom: none;
  }
  .left {
    margin-right: 5px;
    .name {
      font-size: 12px;
      font-weight: 400;
      color: #000000;
      span {
        color: #D53A26;
      }
    }

    .money {
      font-size: 12px;
      font-weight: 400;
      color: #FF0000;
      margin-top: 10px;
    }
    .sku {
      margin-top: 7px;
      display: flex;
      flex-wrap: wrap;
      span {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
      .item {
        height: 19px;
        background: #F9F9F9;
        border-radius: 3px;
        line-height: 19px;
        padding: 0 10px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        margin-left: 5px;
        margin-bottom: 5px;
      }
    }
  }

  .btn {
    width: 60px;
    height: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    text-align: center;
    line-height: 20px;
    &.default {
      background: #00A0E9;
      font-size: 12px;
      font-weight: 500;
      color: #FFFFFF;
    }

    &.update {
      border: 1px solid #00A0E9;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      color: #00A0E9;
    }
  }
}
.buttonGroup {
  position: fixed;
  background-color: #fff;
  bottom: 0;left: 0;
  right: 0;
  padding-bottom: 14px;
  padding-left: 18px;
  padding-right: 18px;
}
</style>
